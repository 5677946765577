import {createSlice} from "@reduxjs/toolkit";
import {WebSocketLike} from "react-use-websocket/dist/lib/types";

export interface webSocketState {
    wsConnection: WebSocketLike | null,
}

const initialState: webSocketState = {
    wsConnection: null,
}

export const webSocketSlice = createSlice({
    name: 'webSocket',
    initialState,
    reducers: {
        setWsConnection: (state, action) => {
            state.wsConnection = action.payload
        }
    }
})

export const {setWsConnection} = webSocketSlice.actions

export default webSocketSlice.reducer
