import {AuthContext} from "./AuthContextProvider";
import {useContext} from "react";
import React from "react"
import {Button, Grid, Stack, Typography} from "@mui/material";
import {DotLoader} from "react-spinners";
import {useAppSelector} from "../../app/hooks";

const IsAuthed = (element: JSX.Element, staff: boolean) => {
    const authContext = useContext(AuthContext)
    const authState = useAppSelector((state) => state.authState)

    if (!authState.authenticated) {
        return (
            <Grid container sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                justifySelf: 'center',
                height: '100vh',
                width: '100%',
                alignSelf: 'center',
            }}>
                <Grid item xs={12} sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    justifySelf: 'center',
                    alignSelf: 'center',
                }}>
                    <DotLoader color="#ffffff"/>
                </Grid>
                <Grid item xs={12} sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    justifySelf: 'center',
                    alignSelf: 'center',
                }}>
                    <Typography variant={"h6"} component="div" sx={{color: 'white'}}>
                        Logging in...
                    </Typography>
                </Grid>
            </Grid>
        )
    }
    if (authState.authenticated) {
        return element
    } else {
        return (
            <Stack direction={"column"} alignItems={"center"} sx={{my: 'auto', mx: 'auto'}}>
                You are not authorized to view this page, please log in.
                <Button variant={"contained"} color={"primary"} onClick={() => {
                    authContext.login()
                }}>Login</Button>
            </Stack>
        )
    }
}

export default IsAuthed
