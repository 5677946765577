import {createSlice} from "@reduxjs/toolkit";

export interface MenuState {
    menuOpen: boolean,
}

const initialState: MenuState = {
    menuOpen: false,
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenuOpen: (state, action) => {
            state.menuOpen = action.payload
        }
    }
})

export const {setMenuOpen} = menuSlice.actions

export default menuSlice.reducer