import {createSlice} from "@reduxjs/toolkit";

export interface MessageState {
    acked: boolean,
    body: string,
    conversation: number,
    created: string,
    id: number,
    recipient: string,
    originator: string,
    sid: string,
    uuid: string,
    newMessage: boolean,
}

const initialState: MessageState = {
    acked: false,
    body: '',
    conversation: 0,
    created: '',
    id: 0,
    recipient: '',
    originator: '',
    sid: '',
    uuid: '',
    newMessage: false,
}

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        setMessageAcked: (state, action) => {
            state.acked = action.payload
        },
        setMessageBody: (state, action) => {
            state.body = action.payload
        },
        setMessageConversation: (state, action) => {
            state.conversation = action.payload
        },
        setMessageCreated: (state, action) => {
            state.created = action.payload
        },
        setMessageId: (state, action) => {
            state.id = action.payload
        },
        setMessageRecipient: (state, action) => {
            state.recipient = action.payload
        },
        setMessageSid: (state, action) => {
            state.sid = action.payload
        },
        setMessageUuid: (state, action) => {
            state.uuid = action.payload
        },
        setMessageState: (state, action) => {
            state = action.payload
        },
        setMessageNewMessage: (state, action) => {
        //     if a message was not in the state before, set newMessage to true
            if (state.uuid !== action.payload) {
                state.newMessage = true
            }
        }
    },
})

export const {
    setMessageAcked,
    setMessageConversation,
    setMessageBody,
    setMessageId,
    setMessageSid,
    setMessageUuid,
    setMessageRecipient,
    setMessageCreated,
    setMessageState
} = messageSlice.actions

export default messageSlice.reducer
