import * as React from 'react';
import {useContext} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SensorsOutlinedIcon from "@mui/icons-material/SensorsOutlined";
import SensorsOffOutlinedIcon from "@mui/icons-material/SensorsOffOutlined";
import {useAppSelector} from "../app/hooks";
import {AuthContext} from "./auth/AuthContextProvider";
// import {KeyRounded} from "@mui/icons-material";
import {Grid} from "@mui/material";

// TODO: the websocket stays connected even when you navigate away from the chat. need to do some state cleanup and store the chat in a session in the store

const ChatAppBar = () => {

    const isTyping = useAppSelector((state) => state.user.isTyping)
    const storeWs = useAppSelector((state) => state.websocket.wsConnection)


    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static"
                    sx={{backgroundColor: '#1E1E1E', backgroundImage: 'none', boxShadow: '0px', p: '0px 0px 0px 0px'}}>
                <Toolbar>
                        <Grid container sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                        <Grid item>
                            {storeWs?.readyState === WebSocket.OPEN ? (
                                <SensorsOutlinedIcon sx={{
                                    color: 'green',
                                    fontSize: '2rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    justifySelf: 'flex-start',
                                    alignSelf: 'flex-start',
                                }}/>
                            ) : (
                                <SensorsOffOutlinedIcon sx={{
                                    color: 'yellow',
                                    fontSize: '2rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    justifySelf: 'flex-start',
                                    alignSelf: 'flex-start',

                                }}/>
                            )}
                        </Grid>
                            {/*{authState && (*/}
                            {/*    <Grid item sx={{color: 'white', display: 'flex', justifySelf: 'flex-end', alignSelf: 'flex-end'}}>*/}
                            {/*    <Button sx={{color: 'white', display: 'flex', justifySelf: 'flex-end', alignSelf: 'flex-end'}}*/}
                            {/*            onClick={() => {*/}
                            {/*                authContext.logout()*/}
                            {/*            }}>*/}
                            {/*        <KeyRounded/>*/}
                            {/*    </Button>*/}
                            {/*    </Grid>*/}
                            {/*)}*/}
                        <Typography variant={'body1'} sx={{
                            color: 'white',
                            mr: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}> {isTyping ? 'Typing...' : ''}</Typography>
                        </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default ChatAppBar;
