import {Box, Button, Card, CardContent, Fade, Modal, Tooltip, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {chatListLoader, getConversationIdByPhoneNumber, loadLastMessage, onErrors} from "../toolFuncs";
import InputFieldMuiTs from "../inputFields/InputFieldMuiTs";
import {Link} from "react-router-dom";
import toastFetchCatcher from "../toasts/ToastFetchCatcher";

export interface NewMessageProps {
    recipient: string,
    body: string,
}

const NewMessageModal = () => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const phoneMatchRegex = new RegExp(/^[+][0-9]{11,12}$/)
    const [conversationId, setConversationId] = useState<number | null>(null)

    const methods = useForm({
        defaultValues: {
            recipient: '',
            body: '',
        }
    })
    const handleClose = () => {
        setOpen(false)
        methods.reset()
        setConversationId(null)
    }

    // use useWatch to see if the recipient field matches the regex, if it does, run the getConversationIdByPhoneNumber with the recipient as the argument

    const recipient = useWatch({
        control: methods.control,
        name: 'recipient',
        defaultValue: '',
    })

    useEffect(() => {
        if (recipient !== '' && phoneMatchRegex.test(recipient)) {
            getConversationIdByPhoneNumber(recipient).then((data) => {
                const {uuid} = data
                setConversationId(uuid)
            }).catch((error) => {
                setConversationId(null)
                console.log(error)
            })
        } else {
            setConversationId(null)
        }
    }, [recipient])

    const inputFields = {
        recipient: {
            required: true,
            placeholder: "Recipient, eg +31612345678",
            minLength: 12,
            maxLength: 12,
            formStyle: {
                width: '100%',
                py: 2,
            },
            fieldStyle: {
                display: 'flex',
            }
        },
        body: {
            required: true,
            placeholder: "Message",
            minLength: 1,
            multiline: true,
            disabled: !!conversationId || !methods.getValues().recipient.match(phoneMatchRegex),
            rows: 6,
            formStyle: {
                py: 2,
            },
            fieldStyle: {
                display: 'flex',
                width: '100%',
            }
        }
    }

    const sendMessage = (data: NewMessageProps) => {
        setLoading(true)
        const message = data.body
        const phoneNumber = data.recipient
        if (message !== '' || undefined) {
            let body = new FormData()
            body.append('body', message)
            body.append('recipient', phoneNumber)
            body.append('originator', '+18337250083')
            fetch(`${process.env.REACT_APP_BACKEND_URL}/message/sms/`, {
                method: 'POST',
                body: body,
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        return Promise.reject(response)
                    }
                })
                .then(() => {
                    setLoading(false)
                    // when the backend returns to us a chat uuid, we can add it to the chat list
                    chatListLoader().then(() => loadLastMessage())
                    handleClose()
                    methods.reset()
                }).catch((error) => {
                console.log(error)
                toastFetchCatcher(error)
                setLoading(false)
            })
        } else {
            console.log(methods.formState.errors)
        }
    }

    return (
        <>
            <Tooltip title={'Add a new chat'}>
                <Fab sx={{
                    position: 'fixed',
                    top: '3px',
                    right: '2rem',
                    boxShadow: 2,
                    display: {xs: 'none', sm: 'flex'},
                }}
                     onClick={() => setOpen(true)}
                     aria-label="add">
                    <AddIcon/>
                </Fab>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={true}
                onClick={(event) => {
                    if (event.target === event.currentTarget) {
                        handleClose()
                    } else {
                        return
                    }
                }}
            >
                <Fade in={open}>
                <Box sx={{
                    bottom: '2rem',
                    width: {xs: '100%', md: '50%', lg: '30%'},
                    height: 'auto',
                    position: 'fixed',
                    p: 2,
                    left: {xs: '0', md: '35%', lg: '65%'},
                    right: '6rem',
                    backgroundColor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    borderRadius: '1rem',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignContent: 'space-between',
                    alignItems: 'space-between',
                    justifyItems: 'space-between'
                }}>
                    {conversationId ? (
                        <Button component={Link} to={`/chat/${conversationId}`} key={Math.random()} sx={{
                            width: '100%',
                        }}>
                            <Card key={Math.random()} sx={{
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: '100%',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyItems: 'center',
                                borderRadius: '20px',
                                display: 'flex'
                            }}>
                                <CardContent key={Math.random()}
                                             sx={{
                                                 display: 'flex',
                                                 flexDirection: 'column',
                                                 justifyContent: 'center',
                                                 width: '100%',
                                                 alignContent: 'center',
                                                 alignItems: 'center',
                                                 justifyItems: 'center'
                                             }}
                                >
                                    <Typography gutterBottom variant="h6" component="div" key={Math.random()}>
                                        {recipient} already has an active chat
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary" key={Math.random()}>
                                        Click here to go to the chat
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Button>
                    ) : null}
                    <form onSubmit={methods.handleSubmit(sendMessage, onErrors)}>
                        <FormProvider  {...methods}>
                            <InputFieldMuiTs inputFields={inputFields}/>
                        </FormProvider>
                        <Button type={"submit"} disabled={loading || !!conversationId} sx={{
                            backgroundColor: '#f5f5f5',
                            boxShadow: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            py: 2,
                            my: 2,
                            color: 'black',
                            textDecoration: 'none',
                            ":hover": {
                                backgroundColor: '#808080',
                                color: 'white',
                            },
                        }}>
                            Send
                        </Button>
                    </form>
                </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default NewMessageModal;
