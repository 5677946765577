import {Grid, InputAdornment} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import {FormProvider, useForm} from "react-hook-form";
import InputFieldMuiTs from "../inputFields/InputFieldMuiTs";
import React from "react";
import {Search} from "@mui/icons-material";
import {setSearchList} from "../../features/chats/searchListSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import ChatListMenuButton from "../menus/ChatListMenuButton";

interface SearchQuery {
    searchForChat: string,
}

const SearchBar = () => {
    const methods = useForm({
        defaultValues: {
            searchForChat: '',
        }
    })

    const dispatch = useAppDispatch()
    const chatListState = useAppSelector((state) => state.chatList)

    const filterChatList = (query: SearchQuery) => {
        if (query.searchForChat === '') {
            return dispatch(setSearchList([]))

        } else {
            const filteredChatList = chatListState.filter((chat) => {
                if (chat.id === undefined) {
                    return false
                }
                //TODO: get the messageList by the chat id and then search all messages for the query

                return chat.label.toString().includes(query.searchForChat) || chat.lastMessage.includes(query.searchForChat)
            })
            console.log(filteredChatList)
            return dispatch(setSearchList(filteredChatList))
        }
    }

    const inputFields = {
        searchForChat: {
            required: false,
            placeholder: "Search for chat",
            formStyle: {
                p: 1
            },
            InputProps: {
                startAdornment: (
                    <InputAdornment position={"start"}>
                        <Search/>
                    </InputAdornment>
                )
            },
            keyUp: () => {
                methods.handleSubmit(filterChatList)()
            }
        }
    }

    return (
        <Grid container sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        }}>
            <Grid item xs={window.location.pathname.includes('/chat') ? 12 : 2} lg={2} sx={{
                minHeight: '60px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
                justifyItems: 'start',
            }}>
                <ChatListMenuButton/>
            </Grid>
            <Grid item xs={10} sx={{
                display: {xs: window.location.pathname.includes('/chat') ? 'none' : 'flex', lg: 'flex'},
            }}>
                <AppBar position="static" sx={{
                    backgroundColor: '#1E1E1E',
                    backgroundImage: 'none',
                    // no boxShadow
                    boxShadow: '0px 0px 0px 0px',
                    p: '0px 0px 0px 0px',
                    width: '100%',
                }}>
                    <form onSubmit={methods.handleSubmit(filterChatList)}>
                        <FormProvider  {...methods}>
                            <InputFieldMuiTs inputFields={inputFields}/>
                        </FormProvider>
                    </form>
                </AppBar>
            </Grid>
        </Grid>
    )
}

export default SearchBar;
