import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import {useAppDispatch} from "../../app/hooks";
import {setMenuOpen} from "../../features/menu/menuSlice";
import {IconButton} from "@mui/material";

const ChatListMenuButton = () => {
    const dispatch = useAppDispatch()
    const handleMenuClick = () => {
        console.log('menu clicked')
        dispatch(setMenuOpen(true))
    }

    return (
        <IconButton id={"menu-button"} onClick={handleMenuClick} sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            flexGrow: 1,
            '&:hover': {
                backgroundColor: '#2E2E2E',
            },

        }}>
            <MenuIcon sx={{
                color: '#FFFFFF',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
                '&:hover': {
                    backgroundColor: '#2E2E2E',
                },
            }}/>
        </IconButton>
    )
}

export default ChatListMenuButton;