import {Grid} from "@mui/material";
import {Outlet} from "react-router-dom";
import React from "react";
import IsAuthed from "./auth/IsAuthed";
import NewMessageModal from "./chat/NewMessageModal";
import ChatList from "./chat/chatList/ChatList";
import {useAppSelector} from "../app/hooks";

const Layout = () => {
    const messageList = useAppSelector((store) => store.messageList)
    return (
        IsAuthed(
            <Grid container sx={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <ChatList/>
                {!messageList && (
                    <Grid item xs={10} sx={{
                        width:'100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center',
                        height: '100vh',
                    }}>
                <h1 style={{color: 'white'}}>Select a chat to start messaging</h1>
            </Grid>
        )}
    <Outlet/>
    <NewMessageModal/>
</Grid>,
    false
)
)
}

export default Layout;
