import {Divider, Grid, IconButton, Slide, Typography} from "@mui/material";
import React, {useContext, useRef} from "react";
import {setMenuOpen} from "../../features/menu/menuSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import FaceIcon from '@mui/icons-material/Face';
import {AuthContext} from "../auth/AuthContextProvider";
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ChatListMenu = () => {
    const menuOpen = useAppSelector((state) => state.menu.menuOpen)
    const dispatch = useAppDispatch()
    const menuRef = useRef<HTMLDivElement>(null)
    const authContext = useContext(AuthContext)
    const {email, name, email_verified,} = authContext.idTokenParsed
    const info = useAppSelector((store) => store.info)
    const {default_number, api_ver, support_email} = info

    const closeOpenMenus = (event: any) => {
        if (menuRef.current && menuOpen && !menuRef.current.contains(event.target)) {
            dispatch(setMenuOpen(false))
        } else {
            return
        }
    }

    document.addEventListener('mousedown', closeOpenMenus)

    return (
        <Slide direction="right" in={menuOpen} mountOnEnter unmountOnExit>
            <Grid
                xs={12}
                id={"menu"}
                ref={menuRef}
                container
                sx={{
                    backgroundColor: '#000000',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    p: '1rem',
                    justifyContent: 'space-between',
                }}
            >
                <Grid container sx={{
                    //     align this grid to the top of the page
                    width: '100%',
                    backgroundColor: '#000000',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'center',
                }}>
                    <Grid item sx={{
                        height: '4rem',
                        width: '4rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'center'
                    }}>
                        <IconButton sx={{
                            color: '#FFFFFF',
                            display: 'flex',
                            '&:hover': {
                                backgroundColor: '#2E2E2E',
                            },
                            height: '100%',
                            width: '100%',

                        }}>
                            <FaceIcon sx={{
                                color: '#FFFFFF',
                                height: '100%',
                                display: 'flex',
                                flexGrow: 1,
                                '&:hover': {
                                    backgroundColor: '#2E2E2E',
                                },
                            }}/>
                        </IconButton>
                    </Grid>

                    <Grid item sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'center'
                    }}>
                        <Typography variant="h6" component={'div'} sx={{
                            color: '#FFFFFF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyItems: 'center',
                            alignContent: 'center',
                            width: '100%',
                        }}>
                            {name}
                        </Typography>
                    </Grid>
                    <Divider sx={{backgroundColor: '#FFFFFF', p: 0, my: 2, width: '100%'}}/>
                    <Grid item sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'row'
                    }}>
                        <Typography variant="body2" component={'div'} sx={{
                            color: '#FFFFFF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyItems: 'center',
                            alignContent: 'center',
                            width: '90%',
                        }}>
                            {email ? email : 'No email found'}
                        </Typography>
                        <Typography variant="body1" component={'div'} sx={{
                            color: '#FFFFFF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyItems: 'center',
                            alignContent: 'center',
                            width: '10%',
                        }}>
                            {email_verified ? <CheckIcon/> : 'X'}
                        </Typography>
                    </Grid>
                    <Grid item sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'row'
                    }}>
                        <IconButton onClick={() => {
                            dispatch(setMenuOpen(false))
                        }} sx={{
                            color: '#FFFFFF',
                            display: 'flex',
                            '&:hover': {
                                backgroundColor: 'none',
                            },
                        }}>
                            <ArrowBackIcon sx={{
                                color: '#FFFFFF',
                                height: '100%',
                                display: 'flex',
                                flexGrow: 1,
                                '&:hover': {
                                    backgroundColor: 'none',
                                },
                            }}/>
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid container sx={{
                    //     align this grid to the bottom of the page
                    width: '100%',
                    backgroundColor: '#000000',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    justifyItems: 'center',
                    alignContent: 'center',
                }}>
                    <Grid item sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'row'
                    }}>
                        <Typography variant="body2" component={'div'} sx={{
                            color: '#FFFFFF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyItems: 'center',
                            alignContent: 'center',
                            width: '100%',
                        }}>
                            From: {default_number ? `+${default_number}` : 'No default number found'}
                        </Typography>
                    </Grid>
                    <Grid item sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'row'
                    }}>
                        <Typography variant="body2" component={'div'} sx={{
                            color: '#FFFFFF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyItems: 'center',
                            alignContent: 'center',
                            width: '100%',
                        }}>
                            {api_ver ? `API Version: ${api_ver}` : 'No API version found'}
                        </Typography>
                    </Grid>
                    <Grid item sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'row'
                    }}>
                        <Typography
                            component={'a'}
                            href={`mailto:${support_email}`}
                            variant="body2"
                            sx={{
                                color: '#FFFFFF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyItems: 'center',
                                alignContent: 'center',
                                width: '100%',
                            }}>
                            {support_email}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    )
}

export default ChatListMenu;