import {createSlice} from "@reduxjs/toolkit";

export interface UserState {
    userId: number,
    username: string,
    isTyping: boolean,
}

const initialState: UserState = {
    userId: 0,
    username: '',
    isTyping: false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserId: (state, action) => {
            state.userId = action.payload
        },
        setUsername: (state, action) => {
            state.username = action.payload
        },
        setIsTyping: (state, action) => {
            state.isTyping = action.payload
        },
    },
})

export const {setUserId, setUsername, setIsTyping} = userSlice.actions

export default userSlice.reducer
