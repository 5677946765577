import {createSlice} from "@reduxjs/toolkit";
import {MessageState} from "./messageSlice";

export interface MessageListState extends Array<MessageState> {}

const initialState: MessageListState = []

export interface MessageList extends Array<MessageState> {}

export const messageListSlice = createSlice({
    name: 'messageList',
    initialState,
    reducers: {
        setMessageList: (state, action) => {
            return state = action.payload
        },
        addMessage: (state, action) => {
        //     if message uuid is not already in the state, add it
            if (state.filter((message) => message.uuid === action.payload.uuid).length === 0) {
                state.push(action.payload)
            } else {
                // if it is in the state, replace it
                state.map((message) => {
                    if (message.uuid === action.payload.uuid) {
                        return action.payload
                    } else {
                        return message
                    }
                })
            }
        }
    }
})

export const {setMessageList, addMessage} = messageListSlice.actions

export default messageListSlice.reducer
