import Keycloak, {KeycloakInitOptions, KeycloakTokenParsed} from "keycloak-js";
import React, {createContext, useEffect} from "react";
import {toast} from "react-toastify";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setAuth} from "../../features/auth/authSlice";

const keycloakInitOptions: KeycloakInitOptions = {
    onLoad: "check-sso",
    checkLoginIframe: false,
    silentCheckSsoFallback: true,
};

const keycloak = new Keycloak({
    realm: "unit-testing",
    url: process.env.REACT_APP_ENV === "dev" ? process.env.REACT_APP_KC_DEV : process.env.REACT_APP_KC_PROD,
    clientId: "testclient",
});

interface AuthContextValues {
    logout: () => void;
    login: () => void;
    doRefresh: () => void;
    parsedToken: KeycloakTokenParsed,
    idTokenParsed: KeycloakTokenParsed,
    accessToken: string,
    refreshToken: string,
    idToken: string,
    roles: object,
    groups: object,
    isStaff: boolean,
}

const defaultAuthContextValues: AuthContextValues = {
    logout: () => {
    },
    login: () => {
    },
    doRefresh: () => {
    },
    parsedToken: {},
    idTokenParsed: {},
    accessToken: "",
    refreshToken: "",
    idToken: "",
    roles: {},
    groups: {},
    isStaff: false,
};

const authenticated = await keycloak.init(
    keycloakInitOptions
);

export const AuthContext = createContext<AuthContextValues>(
    defaultAuthContextValues
);

/**
 * The props that must be passed to create the {@link AuthContextProvider}.
 */
interface AuthContextProviderProps {
    children: JSX.Element;
}

const AuthContextProvider = (props: AuthContextProviderProps) => {
    const dispatch = useAppDispatch()
    const authState = useAppSelector((state) => state.authState)

    useEffect(() => {
        try {
            if(keycloak) {
                if (authenticated) {
                    if(!authState.authenticated) {
                        console.log("user authenticated");
                        dispatch(setAuth(keycloak))
                    }
                }
                if (!authenticated) {
                    console.log("user not authenticated");
                    localStorage.clear()
                    keycloak.login()
                }
            }
        } catch (e) {
            toast.error('Failed to initialize authentication, please try again later')
            console.log(e)
        }
    }, []);

    const logout = () => {
        keycloak.logout();
    };

    const login = () => {
        keycloak.login()
    }

    const parsedToken = keycloak.tokenParsed ? keycloak.tokenParsed : {}
    const accessToken = keycloak.token ? keycloak.token : ""
    const refreshToken = keycloak.refreshToken ? keycloak.refreshToken : ""
    const idToken = keycloak.idToken ? keycloak.idToken : ""
    const roles = keycloak.resourceAccess ? keycloak.resourceAccess : {}
    const groups = parsedToken.groups ? parsedToken.groups : {}
    const isStaff = roles.testclient ? roles.testclient.roles.includes("staff") : false
    const idTokenParsed = keycloak.idTokenParsed ? keycloak.idTokenParsed : {}

    keycloak.onTokenExpired = () => {
        keycloak.updateToken(4).then((refreshed) => {
            if (refreshed) {
                console.log('Token refreshed ' + refreshed);
            } else {
                if(keycloak.isTokenExpired() && !refreshed) {
                toast.error('Failed to refresh token, or the session has expired. Redirecting to login page.');
                keycloak.login()
                }
            }
        }).catch(() => {
            keycloak.login()
            console.log('Failed to refresh token');
        });
    }
    setInterval(() => {
        keycloak.updateToken(4).then((refreshed) => {
            if (refreshed) {
                console.log('Token refreshed' + refreshed);
            } else {
                if(keycloak.isTokenExpired() && !refreshed) {
                    toast.error('Failed to refresh token, or the session has expired. Redirecting to login page.');
                    keycloak.login()
                }
            }
        }).catch(() => {
            console.log('Failed to refresh token');
        });
    }, 120000)

    const doRefresh = () => {
        return keycloak.updateToken(5)
    }

    return (
        <AuthContext.Provider value={{
            logout,
            login,
            doRefresh,
            parsedToken,
            accessToken,
            idToken,
            refreshToken,
            roles,
            groups,
            isStaff,
            idTokenParsed,
        }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
