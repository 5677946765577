import {ChatListObject} from "./chatListSlice";
import {createSlice} from "@reduxjs/toolkit";

const inialState: ChatListObject[] = []

export const searchListSlice = createSlice({
    name: 'searchList',
    initialState: inialState,
    reducers: {
        setSearchList: (state, action) => {
            return state = action.payload
        },
        addSearchList: (state, action) => {
            if(Array.isArray(action.payload)) {
                //     add newChat property to each object, if it is a new chat, it will be true
                return action.payload.map((chat: ChatListObject) => {
                    return {...chat, newChat: chat.newChat === undefined ? true : chat.newChat}
                })} else {

                const chat = action.payload as unknown as ChatListObject
                const newConversation = [{...chat, newChat: chat.newChat === undefined ? true : chat.newChat}]
                return [...state, ...newConversation]

            }
        }
    }
})

export const {setSearchList, addSearchList} = searchListSlice.actions

export default searchListSlice.reducer