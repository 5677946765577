import {createSlice} from "@reduxjs/toolkit";

export interface InfoState {
    allowed_numbers: string[],
    default_number: string,
    api_ver: string,
    support_email: string,
}

const initialState: InfoState = {
    allowed_numbers: [],
    default_number: '',
    api_ver: '',
    support_email: '',
}

export const infoSlice = createSlice({
    name: 'info',
    initialState,
    reducers: {
        setInfo: (state, action) => {
            return state = action.payload
        },
    },
})

export const {setInfo} = infoSlice.actions

export default infoSlice.reducer