import {configureStore, ThunkAction, Action, combineReducers} from '@reduxjs/toolkit';
import {userSlice} from "../features/user/userSlice";
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import {messageSlice} from "../features/messages/messageSlice";
import {messageListSlice} from "../features/messages/messageListSlice";
import {chatListSlice} from "../features/chats/chatListSlice";
import {webSocketSlice} from "../features/chats/webSocketSlice";
import {authSlice} from "../features/auth/authSlice";
import {searchListSlice} from "../features/chats/searchListSlice";
import {menuSlice} from "../features/menu/menuSlice";
import {infoSlice} from "../features/info/infoSlice";

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    user: userSlice.reducer,
    message: messageSlice.reducer,
    messageList: messageListSlice.reducer,
    chatList: chatListSlice.reducer,
    websocket: webSocketSlice.reducer,
    authState: authSlice.reducer,
    searchList: searchListSlice.reducer,
    menu: menuSlice.reducer,
    info: infoSlice.reducer,
})

const persistedReducer = persistReducer(
    persistConfig,
    rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
    middleware: [thunk],
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
