import {isRouteErrorResponse, useRouteError} from "react-router-dom";
import {useEffect, useState} from "react";
import {Grid} from "@mui/material";

const ChatErrorsElement = () => {
    const [errorMessage, setErrorMessage] = useState('')
    const error = useRouteError();

    useEffect(() => {
        if(isRouteErrorResponse(error)) {
            switch (error.status) {
                case 404:
                    setErrorMessage('Chat not found')
                    break
                case 403:
                    setErrorMessage('You are not authorized to view this chat')
                    break
                default:
                    setErrorMessage('Something went wrong')
            }
        }
        console.log(error)
    }, [error])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                overflow: 'hidden',
            }}>
            <Grid item>
                <h1>{errorMessage}</h1>
            </Grid>
        </Grid>
    )
}

export default ChatErrorsElement;