import {toast} from "react-toastify";

const ToastFetchCatcher = (error: any) => {
    if (error) {
        console.log(error.status)
        if (error.status === 500 ) {
            toast.error("Something went wrong.")
        } else if(error.statusText === "No content") {
            toast.error("No content.")
        } else if(error.status === 401) {
            toast.error("Unauthorized.")
        } else if(error.status === 403) {
            toast.error("Forbidden.")
        } else if(error.status === 404) {
            toast.error("Not found.")
        } else if(error.status === 400) {
            toast.error("Bad request.")
        } else if(error.status === 409) {
            toast.error("Conflict.")
        } else if(error.status === 422) {
            toast.error("Unprocessable entity.")
        } else if(error.status === 429) {
            toast.error("Too many requests.")
        } else if(error.status === 503) {
            toast.error("Service unavailable.")
        } else if(error.status === 504) {
            toast.error("Gateway timeout.")
        } else if(error.status === 408) {
            toast.error("Request timeout.")
        }
    }
}

export default ToastFetchCatcher
