import {createSlice} from "@reduxjs/toolkit";

export interface AuthState {
    authenticated: boolean,
    idToken: any,
    refreshToken: any,
    accessToken: any,
    expiresIn: any,
    refreshExpiresIn: any,
    tokenType: any,
    tokenLifetime: any,
    sessionState: any,
    scope: any,
    idTokenParsed: any,
    tokenParsed: any,
    refreshTokenParsed: any,
    isStaff: any,
    userType: any,
    userId: any,
    userEmail: any,

}

const initialState = {
    authenticated: false,
    idToken: '',
    refreshToken: '',
    accessToken: '',
    expiresIn: '',
    refreshExpiresIn: '',
    tokenType: '',
    tokenLifetime: '',
    sessionState: '',
    scope: '',
    idTokenParsed: {},
    tokenParsed: {},
    refreshTokenParsed: {},
    userType: '',
    userId: '',
    userEmail: '',
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            console.log(action.payload)
            return state = action.payload
        }
    }
})

export const {setAuth} = authSlice.actions

export default authSlice.reducer
