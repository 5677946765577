import {Button, Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import React, {useEffect} from "react";
import {chatListLoader, loadLastMessage} from "../../toolFuncs";
import SearchBar from "../../searchBar/SearchBar";
import {useAppSelector} from "../../../app/hooks";
import ChatListMenu from "../../menus/ChatListMenu";

await chatListLoader()

const ChatList = () => {

    const getChatList = useAppSelector((state) => state.chatList)
    const getSearchChatList = useAppSelector((state) => state.searchList)
    const menuOpen = useAppSelector((state) => state.menu.menuOpen)

    useEffect(() => {
        loadLastMessage()
    }, [])

    // TODO: if the menu is opened on mobile the chatlist needs to take up the entire page if the path includes chat

    return (
        <Grid item xs={window.location.pathname.includes('/chat') && !menuOpen ? 2 : 12} lg={2}
              sx={{
                  height: '100vh',
                  backgroundColor: '#1E1E1E',
                  width: '100%',
              }}>
            <Grid item xs={12} sx={{
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
            }}>
                {menuOpen ? (
                    <ChatListMenu/>
                ) : <SearchBar/>}
            </Grid>
            {!menuOpen && getChatList.length > 0 && Object.entries(getSearchChatList.length > 0 ? getSearchChatList : getChatList).map((key) => {
                return (
                    //TODO: need to redo this entire thing in a grid container
                    <Grid key={Math.random()} item
                          xs={12}
                          sm={12}
                          sx={{
                              height: '64px',
                          }}>
                        <Button disableRipple component={Link} to={`/chat/${key[1].uuid}`} key={Math.random()} sx={{
                            padding: '0px 0px 0px 0px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            height: '64px',
                            color: "#FFFFFF",
                        }}>
                            <Card key={Math.random()} sx={{
                                flexDirection: 'row',
                                width: '100%',
                                borderRadius: '0px',
                                display: 'flex',
                                height: '64px',
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyItems: 'center',
                                boxShadow: 0,
                                '&:hover': {
                                    backgroundColor: '#2E2E2E',
                                },

                            }}>
                                <CardMedia
                                    component="img"
                                    image={`https://avatars.dicebear.com/api/initials/richard.svg`}
                                    alt="user avatar"
                                    sx={{
                                        width: '44px',
                                        height: '44px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyItems: 'center',
                                        margin: 'auto',
                                        marginX: '1rem',
                                    }}
                                />
                                <CardContent key={Math.random()}
                                             sx={{
                                                 display: {
                                                     md: 'flex',
                                                     xs: window.location.pathname.includes('/chat') ? 'none' : 'flex'
                                                 },
                                                 flexDirection: 'column',
                                                 width: '80%',
                                                 justifyContent: 'start',
                                                 alignContent: 'start',
                                                 alignItems: 'start',
                                             }}>
                                    <Grid container sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignContent: 'space-between',
                                        alignItems: 'space-between',
                                        justifyItems: 'space-between',
                                        width: '100%',
                                    }}>
                                        <Grid item xs={6} sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            alignContent: 'start',
                                            alignItems: 'start',
                                            justifyItems: 'start',
                                            width: '100%',
                                        }}>
                                            <Typography variant={"subtitle1"} component="div" key={Math.random()}>
                                                <strong>{key[1]?.label}</strong>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'end',
                                            alignContent: 'end',
                                            alignItems: 'end',
                                            justifyItems: 'end',
                                        }}>
                                            <Typography variant={"subtitle1"} component="div" key={Math.random()} sx={{
                                                //     make text 50% opacity
                                                opacity: .5,
                                                textAlign: 'right',
                                            }}>
                                                {new Date(key[1]?.created).toLocaleTimeString('en-US', {
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    hour12: true,
                                                })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            alignContent: 'start',
                                            alignItems: 'start',
                                            justifyItems: 'start',
                                        }}>
                                            <Typography variant="subtitle1" component="div" noWrap sx={{
                                                opacity: .5,
                                            }}
                                                        key={Math.random()}>
                                                {key[1]?.lastMessage}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Button>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default ChatList;
