import {Grid, Typography} from "@mui/material";

const FourOhFour = () => {
    return (
        <Grid container sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            overflow: 'hidden',
        }}>
            <Grid item>
                <Typography variant="h1" component="div" sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    py: 2,
                }}>
                    404
                </Typography>
            </Grid>
        </Grid>
    )
}

export default FourOhFour;