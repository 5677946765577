import {createSlice} from "@reduxjs/toolkit";

export interface ChatListObject {
    created: string,
    id: number,
    is_closed: boolean,
    uuid: string,
    newChat: boolean,
    label: string,
    lastMessage: string,
}

const initialState: ChatListObject[] = []

export const chatListSlice = createSlice({
    name: 'chatList',
    initialState,
    reducers: {
        setChatList: (state, action) => {
            if (Array.isArray(action.payload)) {
                return action.payload.map((chat: ChatListObject) => {
                    return {...chat, newChat: chat.newChat === undefined ? true : chat.newChat}
                })
            } else {
                const chat = action.payload as unknown as ChatListObject
                const newConversation = [{...chat, newChat: chat.newChat === undefined ? true : chat.newChat}]
                return [...state, ...newConversation]
            }
        },
        setChatOld: (state, action) => {
            return state.map((chat: ChatListObject) => {
                if (chat.uuid === action.payload) {
                    return {...chat, newChat: false}
                } else {
                    return chat
                }
            })
        },
        setLastMessage: (state, action) => {
            return state.map((chat: ChatListObject) => {
                if (chat.id ===  action.payload.conversation) {
                    return {...chat, lastMessage: action.payload.body}
                } else {
                    return chat
                }
            })
        },
    },
})

export const {setChatList, setChatOld, setLastMessage} = chatListSlice.actions

export default chatListSlice.reducer
