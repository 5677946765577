import {Card, Grid, Typography} from "@mui/material";
import React from "react";
import {MessageListState} from "../../features/messages/messageListSlice";
import {useAppSelector} from "../../app/hooks";

interface ChatBubbleProps {
    messages: MessageListState,
    newMessage: boolean,
}

const ChatBubble = ({messages}: ChatBubbleProps) => {
    // generate a static hexadecimal color of 6 digits based on the originator number
    const distinctPhoneNumbers = Array.from(new Set(messages.map((message) => message.originator)));

    const colorMap: { [phoneNumber: string]: string } = {};

    distinctPhoneNumbers.forEach((phoneNumber, index) => {
        colorMap[phoneNumber] = index === 0 ? "black" : "#ef8d01";
    });

    const instancePhoneNumber = useAppSelector((store) => store.info.default_number)

    function getAlignmentForOriginator(originator: string, currentUserPhoneNumber: string): 'flex-start' | 'flex-end' {
        console.log('originator', originator)
        console.log('currentUserPhoneNumber', currentUserPhoneNumber)
        if (originator === `+${currentUserPhoneNumber}`) {
            return 'flex-end';
        } else {
            return 'flex-start';
        }
    }

    if (!messages) {
        console.log('i think there are no messages')
        return (
            <Typography variant="body1"
                        component={'div'}
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyItems: 'center',
                        }}>
                Loading...
            </Typography>
        )
    } else {
        return (
            <>
                {
                    Object.entries(messages).map(([key, value]) => {
                        if (value.body === '') {
                            return null
                        } else {
                            return (
                                <Grid item xs={12} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: getAlignmentForOriginator(value.originator, instancePhoneNumber),
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                    width: '100%',
                                }}>
                                    <Card key={key}
                                          sx={{
                                              display: 'flex',
                                              width: 'fit-content',
                                              height: 'fit-content',
                                              flexDirection: 'column',
                                              justifyContent: 'center',
                                              alignSelf: getAlignmentForOriginator(value.originator, instancePhoneNumber),
                                              justifyItems: 'center',
                                              p: 4,
                                              mb: 2,
                                              borderRadius: '10px',
                                              backgroundColor: colorMap[value.originator],
                                              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                                          }}>
                                        <Typography variant="body1"
                                                    component={'div'}
                                                    sx={{
                                                        height: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyItems: 'center',
                                                        wordWrap: "break-word"
                                                    }}>
                                            {value.body}
                                        </Typography>
                                    </Card>
                                </Grid>
                            )
                        }
                    })
                }
            </>
        )
    }
}

export default ChatBubble
