import {store} from "../app/store";
import {ChatListObject, setChatList, setLastMessage} from "../features/chats/chatListSlice";
import {MessageState} from "../features/messages/messageSlice";
import {addMessage} from "../features/messages/messageListSlice";
import {NewMessageProps} from "./chat/NewMessageModal";
import {WebSocketMessage} from "react-use-websocket/dist/lib/types";
import toastFetchCatcher from "./toasts/ToastFetchCatcher";
import {toast} from "react-toastify";

export const stupidScrollToBottom = () => {
    const chatBox = document.getElementById('chatBox')
    if (chatBox !== null) {
        const scrollHeight = chatBox.scrollHeight
        const height = chatBox.clientHeight
        const maxScrollTop = scrollHeight - height
        chatBox.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
    }
}

export const sendSms = async (data: NewMessageProps) => {
    const {recipient, body} = data
    if (body !== '') {
        let requestBody = new FormData()
        requestBody.append('body', body)
        requestBody.append('recipient', recipient.toString())
        fetch(`${process.env.REACT_APP_BACKEND_URL}/message/sms/`, {
            method: 'POST',
            body: requestBody,
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    return Promise.reject(response)
                }
            })
            .then((data) => {
                return store.dispatch(addMessage(data as MessageState))
            })
    }
}

export const getConversationIdByPhoneNumber = (phoneNo: string) => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/conversation/?phone_no=${phoneNo}`, {
        method: 'GET',
    }).then(response => {
        if (response.ok) {
            return response.json()
        } else {
            return Promise.reject(response)
        }
    }).then(data => {
        return data
    }).catch(error => {
        return error
    })
}

export const replyToConversation = (data: MessageState, connectedWs: (message: WebSocketMessage, keep?: boolean) => void) => {
    const message = data.body
    if (message !== '') {
        connectedWs(message)
    }
    return data
}

export const chatListLoader = () => {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/conversation/', {
        method: 'GET',
    }).then(response => {
        if (response.ok) {
            return response.json()
        } else {
            return Promise.reject(response)
        }
    }).then(data => {
        store.dispatch(setChatList(data as ChatListObject[]))
        return data
    }).catch(error => {
        // wipe state on error
        store.dispatch(setChatList([]))
        return error
    })
}

export const loadLastMessage = () => {
    const chatList = store.getState().chatList
    const promises = chatList.map((chat: ChatListObject) => {
            return fetch(`${process.env.REACT_APP_BACKEND_URL}/message/sms/?conversation_uuid=${chat.uuid}&latest=true`, {
                method: 'GET',
            }).then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    return Promise.reject(response)
                }
            }).then(data => {
                if (data.body) {
                    return data
                } else {
                    return null
                }
            }).catch(error => {
                toastFetchCatcher(error)
                return error
            })
        }
    )

    Promise.all(promises).then((data) => {
        const messages = data as MessageState[]
        messages.forEach((message: MessageState) => {
            if (message !== null) {
                store.dispatch(setLastMessage(message))
            } else {
                toast('No messages found', {
                    type: 'error',
                });
            }
        })
    })
}

export const getLatestSmsMessageByConvUUID = async () => {
}

    // errors for the form
    export const onErrors = (errors: object) => {
        return console.log(errors)
    }
