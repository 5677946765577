import {FormGroup, Grid} from "@mui/material";
import React, {useEffect} from "react";
import InputFieldMuiTs from "../inputFields/InputFieldMuiTs";
import {FormProvider, useForm} from "react-hook-form";
import ChatBubble from "./ChatBubble";
import {onErrors, stupidScrollToBottom} from "../toolFuncs";
import {useLoaderData} from "react-router-dom";
import {setWsConnection} from "../../features/chats/webSocketSlice";
import MessageList, {MessageState} from "../../features/messages/messageSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setMessageList} from "../../features/messages/messageListSlice";
import useWebSocket from 'react-use-websocket';
import ChatAppBar from "../ChatAppBar";
import {toast} from "react-toastify";

interface ChatBoxParams {
    conversationId: number,
}

const ChatBox = () => {
    // get params from the loader
    const params = useLoaderData()

    const {conversationId} = params as ChatBoxParams

    const [newMessage, setNewMessage] = React.useState(false)

    // store setup
    const dispatch = useAppDispatch()
    // const isTyping = useAppSelector((store) => store.user.isTyping)
    const wsState = useAppSelector((store) => store.websocket.wsConnection)

    //web socket things
    const {
        sendMessage,
        getWebSocket,
    } = useWebSocket(`${process.env.REACT_APP_WS_URL}?conversation_uuid=${conversationId}`, {
        onOpen: () => {
            dispatch(setWsConnection(getWebSocket()))
        },
        onError: () => {
            setTimeout(() => {
                dispatch(setWsConnection(null))
            }, 5000)
        },
        onMessage: (event) => {
            if (event.data !== "message received") {
                const data = JSON.parse(event.data)
                if (data.message.body !== "message received") {
                    //TODO: currently this just does an api call when a new message is sent. we will use the websocket in the future.
                    fetch(`${process.env.REACT_APP_BACKEND_URL}/message/sms/?conversation_uuid=${conversationId}`)
                        .then((response) => {
                            if (response.ok) {
                                return response.json()
                            } else {
                                return Promise.reject(response)
                            }
                        }).then((data) => {
                        setNewMessage(true)
                        dispatch(setMessageList(data as typeof MessageList))
                        setTimeout(() => {
                            setNewMessage(false)
                        }, 5000)
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            }
        },
        reconnectAttempts: 10,
        reconnectInterval: 3000,
    }, true);

    // methods for the form
    const methods = useForm({
        defaultValues: {
            acked: false,
            body: '',
            conversation: 0,
            created: '',
            id: 0,
            recipient: '',
            originator: '',
            sid: '',
            uuid: '',
            newMessage: false,
        }
    })

    // input fields for the form
    const inputFields = {
        body: {
            required: true,
            placeholder: "Message",
            minLength: 1,
            keyUp: (event: React.KeyboardEvent<HTMLInputElement>) => enterPress(event),
            disabled: !wsState,
            multiline: true,
            maxRows: 6,
            formStyle: {
                display: 'flex',
                width: '100%',
                px: 1
            }
        }
    }

    const sendWsMessage = (data: MessageState) => {
        if (data.body !== '\n') {
            sendMessage(data.body)
            fetch(`${process.env.REACT_APP_BACKEND_URL}/message/sms/?conversation_uuid=${conversationId}`)
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        return Promise.reject(response)
                    }
                }).then((data) => {
                setNewMessage(true)
                dispatch(setMessageList(data as typeof MessageList))
                setTimeout(() => {
                    setNewMessage(false)
                }, 5000)
            }).catch((error) => {
                console.log(error)
            })
        } else {
            methods.reset()
            alert('Please enter a message')
        }
    }

    // // watch for changes in the form and if there are any, set typing to true.
    // const watcher = useWatch(methods)
    //
    // // set isTyping to false after 1 second of no typing. after each keystroke, reset the timer with the debouncer
    // useEffect(() => {
    //     let debouncer = setTimeout(() => {
    //         dispatch(setIsTyping(false))
    //     }, 1000);
    //     return () => {
    //         clearTimeout(debouncer);
    //     }
    // }, [dispatch, watcher])

    // if enter is pressed, send the message and reset the form. for every other keystroke, set isTyping to true
    const enterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            sendWsMessage(methods.getValues())
            methods.reset()
            // dispatch(setIsTyping(false))
        } else {
            console.log(methods)
        }
        // else {
        //     if (!isTyping) {
        //         dispatch(setIsTyping(true))
        //     }
        // }
    }

    const messages = useAppSelector((store) => store.messageList)

    // scroll to the bottom of the chat box after every message
    useEffect(() => {
        stupidScrollToBottom()
    }, [messages])


    // set the conversationId from the params
    useEffect(() => {
        if (conversationId) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/message/sms/?conversation_uuid=${conversationId}`)
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        return Promise.reject(response)
                    }
                }).then((data) => {
                return dispatch(setMessageList(data as typeof MessageList))
            }).catch((error) => {
                console.log(error)
                return toast.error('Error fetching messages')
            })
        }
    }, [conversationId, dispatch])

    const menuOpen = useAppSelector((state) => state.menu.menuOpen)


    return (
        <Grid item xs={10} sx={{
            display: {xs: !menuOpen ? 'flex' : 'none', lg: 'flex'},
            flexDirection: 'column',
            height: '100vh',
        }}>
            <ChatAppBar/>

            <Grid item order={2}
                  id="chatBox"
                  sx={{
                      width: '100%',
                      alignContent: 'end',
                      alignItems: 'end',
                      justifyItems: 'end',
                      flexDirection: 'column',
                      overflow: 'scroll',
                      display: 'flex',
                      p: 2,
                  }}>
                {messages && (
                    <ChatBubble newMessage={newMessage} messages={messages}/>
                )}
            </Grid>
            <Grid item order={3} sx={{
                mb: 2,
                width: '100%',
            }}>
                <form onSubmit={methods.handleSubmit(sendWsMessage, onErrors)}>
                    <FormProvider  {...methods}>
                        <FormGroup sx={{
                            //     make the form fit in the grid item
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                            <InputFieldMuiTs inputFields={inputFields}/>
                        </FormGroup>
                    </FormProvider>
                </form>
            </Grid>
        </Grid>
    )
}

export default ChatBox;
