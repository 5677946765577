import React, {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {persistor, store} from './app/store';
import './index.css';
import {PersistGate} from "redux-persist/integration/react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ChatBox from "./components/chat/ChatBox";
import ChatErrorsElement from "./components/chat/ChatErrorsElement";
import Layout from "./components/Layout";
import FourOhFour from "./components/FourOhFour";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import AuthContextProvider from "./components/auth/AuthContextProvider";
import {DotLoader} from "react-spinners";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {setInfo} from "./features/info/infoSlice";


const container = document.getElementById('root')!;
const root = createRoot(container);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ChatErrorsElement/>,
        loader: async () => {
            const promise = async () => fetch(`${process.env.REACT_APP_BACKEND_URL}/info/`)
                .then((res) => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        return Promise.reject(res)
                    }
                }).then(data => {
                    return store.dispatch(setInfo(data))
            })
            return await promise()
        },
        children: [
            {
                path: "/chat/:conversationId",
                element: <ChatBox/>,
                loader: async ({params}) => {
                    return params
                },
                errorElement: <ChatErrorsElement/>,
            },
            {
                path: "*",
                element: <FourOhFour/>,
            }
        ],
    },
]);

const theme = createTheme({
    // create my own custom typography variant
    typography: {
        fontFamily: 'Roboto',
        subtitle1: {
            fontSize: '1rem',
            textTransform: 'none'
        },
        subtitle2: {
            fontSize: '.8rem',
            textTransform: 'none'
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        },
    },
})

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

root.render(
    <StrictMode>
        <Provider store={store}>
            <PersistGate loading={<DotLoader
                color={'#FFFFFF'}
                loading={true}
                size={150}
                speedMultiplier={1}
            />} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <AuthContextProvider>
                        <RouterProvider router={router}/>
                    </AuthContextProvider>
                    <ToastContainer/>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </StrictMode>
);
