import {useFormContext} from "react-hook-form";
import {FormGroup, TextField} from "@mui/material";
import React, {memo} from 'react'

interface InputProps {
    inputFields: {
        [key: string]: {
            placeholder?: string,
            dataCy?: string,
            required?: boolean,
            minLength?: number,
            maxLength?: number,
            pattern?: RegExp,
            message?: string,
            keyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
            disabled?: boolean,
            fieldStyle?: object,
            formStyle?: object,
            multiline?: boolean,
            InputProps?: object,
            maxRows? : number
        }
    },
    key?: string,
}

const MaterialInput = (props: InputProps) => {
    const {register, formState: {errors},} = useFormContext()
    const textFields: Array<React.JSX.Element> = []
    const {inputFields, key} = props as InputProps

    Object.entries(inputFields).map(([name, i]: [string, object]) => {

        const {ref: nameRef, ...inputProps} = register(name as never,
            inputFields[name]
        )

        return textFields.push(
            <FormGroup key={key ? key : `create-business-${name}-form-group`} sx={{...inputFields[name]['formStyle']}}>
            <TextField
                key={key ? key : `create-business-${name}-input`}
                onKeyUp={inputFields[name]['keyUp'] ? inputFields[name]['keyUp'] : () => {
                }}
                multiline={inputFields[name]['multiline'] ? inputFields[name]['multiline'] : false}
                sx={{...inputFields[name]['fieldStyle']}}
                error={!!errors[name]}
                placeholder={inputFields[name]['placeholder'] ? inputFields[name]['placeholder'] : name}
                id={name}
                InputProps={inputFields[name]['InputProps'] ? inputFields[name]['InputProps'] : {}}
                data-cy={inputFields[name]['dataCy'] ? inputFields[name]['dataCy'] : (Math.random() + 1).toString(36).substring(7)}
                type={name.match(/(password)/) ? "password" : "text"}
                inputRef={nameRef}
                disabled={inputFields[name]['disabled'] ? inputFields[name]['disabled'] : false}
                maxRows={inputFields[name]['maxRows']}
                {...inputProps}
            />
            </FormGroup>
        )
    })
    return (
        <>{textFields}</>
    )
}

export default memo(MaterialInput)
